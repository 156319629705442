<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="SNtip">
        {{ $t("sciencePopularization.scienceNews.SN_tip") }}
      </div>

      <div
        class="newsItem"
        v-for="(item, index) in newList"
        :key="index"
        @click="newsDetails(item)"
      >
        <img :src="item.image" alt="" />
        <div class="itemCont">
          <div class="title">{{ item.title }}</div>
          <div class="cont" v-html="item.content"></div>
          <div class="see">
            <div>{{ $t("sciencePopularization.scienceNews.SN_see") }}</div>
            <img src="@/assets/img/checklan.png" alt="" />
          </div>
        </div>
      </div>
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="3"
          layout="total,prev,pager,next,jumper"
          :total="count"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-行业科普-科普新闻
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-17 15:49
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "行业科普",
          title_en: "Industry science popularization",
          path: "sciencePopularization",
        },
        {
          title_zh: "科普新闻",
          title_en: "Popular science news",
          path: "",
        },
      ],

      newList: [],
      currentPage: 1,
      count: 0,
    };
  },
  mounted() {
    this.getNewsInfo();
  },
  methods: {
    /**
     * 分页
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getNewsInfo();
    },
    /**
     * 新闻列表
     * 刘嘉鑫
     * 2022-8-17
     */
    getNewsInfo() {
      this.$request({
        url: "/Index/newsList",
        data: {
          type_id: 4, // 分类id
          page: this.currentPage,
        },
      }).then((res) => {
        console.log("科普新闻", res);
        this.newList = res.news_list;
        this.count = res.news_count;
        for (let i in this.newList) {
          var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
          this.newList[i].content = this.newList[i].content.replace(re1, ""); //执行替换成空字符
        }
      });
    },

    /**
     * 新闻详情
     * 刘嘉鑫
     * 2022-8-18
     */
    newsDetails(item) {
      this.$router.push({
        path: "newsCenterDetails",
        query: { id: item.id, title: item.title },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 60px 310px;
  background: #f6f6f6;
}

.SNtip {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  margin-bottom: 50px;
}

.newsItem {
  padding: 10px 130px 10px 10px;
  background: #ffffff;
  margin-bottom: 40px;
  display: flex;
  cursor: pointer;
  > img {
    width: 180px;
    height: 180px;
    margin-right: 40px;
    object-fit: cover;
  }

  .itemCont {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 81%;

    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
      margin-bottom: 19px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .cont {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .see {
      display: flex;
      align-items: center;
      margin-top: auto;
      > div {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1a2a60;
        margin-right: 10px;
      }
      > img {
        width: 20px;
        height: 12px;
      }
    }
  }
}

.newsItem:hover {
  .see {
    > img {
      animation: checkImg 0.7s linear infinite;
      position: relative;
    }
  }
}
</style>